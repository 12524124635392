import Vue from "vue";
import * as types from "./types";

export default {
  [types.SET_CONST_TYPE_LIST](state, payload) {
    Vue.set(state, "constantObjectList", payload);
  },
  [types.SET_DEFAULT_COLUMNS](state, payload) {
    Vue.set(state, "defaultColumns", payload);
  }
};
