export default {
  timeLogList: [],
  purchaseOrderList: [],
  expenseList: [],
  estimateList: [],
  buildings: [],
  invoiceList: [],
  invoiceGroupList: [],
  recurringInvoiceList: [],
};
