import Vue from "vue";
import * as types from "./types";

export default {
  setDetailId(state, data) {
    state.detailId = data;
  },

  [types.SET_PRODUCT_LIST](state, payload) {
    Vue.set(state, "objectList", payload);
  },
  [types.SET_PRODUCT_ITEM_LIST](state, payload) {
    Vue.set(state, "productItemList", payload);
  }
};
