import { FirebaseActions } from "../../../../services/firebase.service";
// import * as types from "./types";

export default tenant => {
  const workOrder = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "workOrder"
  );

  // const workOrderAction = new FirebaseActions(
  //   `/system_client/${tenant}/work_order_dashboard`,
  //   "workOrderAction"
  // );

  // const workOrderTask = new FirebaseActions(
  //   `/system_client/${tenant}/work_order_task`,
  //   "workOrderTask"
  // );

  return {
    // bindWorkOrder: workOrder.bindDocument,
    // bindWorkOrderList: workOrder.bindCollection,
    bindWorkOrderListBys: workOrder.bindCollectionBys,
    unbindWorkOrderList: workOrder.unbindCollection
    // createWorkOrder: workOrder.createDocument,

    // bindWorkOrderAction: workOrderAction.bindDocument,
    // bindWorkOrderActionList: workOrderAction.bindCollection,

    // bindWorkOrderTaskListBy: workOrderTask.bindCollectionBy,
    // bindWorkOrderTaskListBys: workOrderTask.bindCollectionBys,
    // bindWorkOrderTask: workOrderTask.bindDocument,
    // createWorkOrderTask: workOrderTask.createDocument,
    // updateWorkOrderTask: workOrderTask.updateDocument,
    // deleteWorkOrderTask: workOrderTask.deleteDocument,

    // bindWorkOrderResponse({ commit }, actionId) {
    //   FirebaseActions.getCollection(
    //     `/system_client/${tenant}/work_order_dashboard/${actionId}/response`
    //   ).then(response => {
    //     commit(types.SET_WO_TASK_RESPONSE, response);
    //   });
    // }
  };
};
