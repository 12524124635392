export default {
  // workOrderList: [],
  // workOrder: {},

  workOrderActionList: [],
  workOrderAction: {},

  workOrderTaskList: [],
  workOrderTask: {},

  response: [],
  searchText: "",
  weekFilter: [],

  purchaseOrderList: [],
  purchaseOrder: {},
  poResponse: []
};
