import _ from "lodash";
import { ACTION_CODE_CONSTRUCTION } from "@/utility/const";
import { toDisplayDateString } from "../../../../utility/datetime";
import { getFullAddress } from "../../../../utility/address";

export default {
  filterActions: state => boardName => {
    return state.actionList
      .filter(action => {
        return action.boards ? action.boards.includes(boardName) : false;
      })
      .sort((a, b) => {
        return a.code - b.code;
      });
  },

  filterCards: (state, getters) => actionId => {
    return getters.cardList.filter(card => {
      return card.actions ? card.actions.includes(actionId) : false;
    });
  },

  filterCardsGrid: (state, getters) => boardName => {
    let actions = getters.filterActions(boardName);
    let cards = [];
    for (const action of actions) {
      cards.push(
        ..._.cloneDeep(getters.filterCards(action.id)).map(item => {
          item.actionTitle = action.title;
          item.actionId = action.id;
          return item;
        })
      );
    }

    return cards;
  },

  filterAction: state => actionId => {
    return state.actionList.find(action => action.id === actionId) || {};
  },

  actionListSorted: state => {
    if (state.businessCode === "commercial") {
      return _.cloneDeep(state.actionList)
        .filter(item => {
          return (
            item.boards.includes("sales") ||
            item.boards.includes("pre-construction") ||
            item.boards.includes("post-construction") ||
            item.code === ACTION_CODE_CONSTRUCTION
          );
        })
        .map(item => {
          if (item.code === ACTION_CODE_CONSTRUCTION) {
            item.title = "Construction";
          }
          return item;
        })
        .sort((a, b) => {
          return a.code - b.code;
        });
    } else if (state.businessCode === "residential") {
      return _.cloneDeep(state.actionList)
        .filter(item => {
          return item.boards.includes("residential");
        })
        .sort((a, b) => {
          return a.code - b.code;
        });
    } else {
      return [];
    }
  },

  responseSorted: state => {
    return _.cloneDeep(state.response).sort((a, b) => {
      return a.index - b.index;
    });
  },

  response: state => state.response,

  boardCode: state => state.boardCode,

  actionByCode: state => actionCode => {
    return state.actionList.find(action => action.code === actionCode) || {};
  },

  constructionProjectList: (state, _, __, rootGetters) => {
    const propertyList = rootGetters["dashboard/client/propertyList"];
    const projectResult = state.constructionProjectList
      .filter(item => !!item.cardNumber)
      .map(project => {
        const property =
          propertyList.find(property => property.id === project.propertyId) ||
          {};
        return {
          ...project,
          property
        };
      });
    return projectResult;
  },

  constructionProjectId: state => state.constructionProjectId,

  constructionProjectNumber: state => state.constructionProjectNumber,

  constructionProject: state =>
    state.constructionProjectList.find(
      r => r.id === state.constructionProjectId
    ) || {},

  users: state => {
    const result = [];
    state.users.forEach(user => {
      const email =
        user.customClaims.loginMethod === "email_by_phone"
          ? user.customClaims.displayEmail || ""
          : user.email;
      result.push({ ...user, email });
    });
    return result;
  },

  activeUsers: (state, _, __, rootGetters) => {
    const groupListByUserId = rootGetters["dashboard/group/groupListByUserId"];
    const activeUsers = state.users.filter(user => !user.disabled);
    const userList = [];
    activeUsers.filter(user => {
      const groupList = groupListByUserId(user.uid);

      const isCommercial = groupList.some(
        group =>
          group.menuIDs &&
          group.menuIDs.includes("dashboard-main/commercial-board")
      );
      const isResidential = groupList.some(
        group =>
          group.menuIDs &&
          group.menuIDs.includes("dashboard-main/residential-board")
      );

      if (
        state.cardList[0] &&
        state.cardList[0].businessCode === "commercial" &&
        isCommercial
      ) {
        userList.push(user);
      } else if (
        state.cardList[0] &&
        state.cardList[0].businessCode === "residential" &&
        isResidential
      ) {
        userList.push(user);
      }
    });
    return userList.length > 0 ? userList : activeUsers;
  },

  userById: state => id => state.users.find(user => user.uid === id) || {},

  card: state => state.card,

  action: state => state.action,
  actionList: state => state.actionList,
  cardList: (state, getters, rootState, rootGetters) => {
    const companyById = rootGetters["dashboard/client/companyById"];
    const propertyById = rootGetters["dashboard/client/propertyById"];
    const contactById = rootGetters["dashboard/client/contactById"];

    let newList = state.cardList.filter(item => !!item.cardNumber);

    if (state.userFilter && state.userFilter.length > 0) {
      newList = newList.filter(card => {
        for (const uid of state.userFilter) {
          if ((card.assigneeIDs || []).includes(uid)) {
            return true;
          }
        }
        return false;
      });
    }
    let searchText = state.searchText.toLowerCase();
    if (searchText) {
      newList = newList.filter(card => {
        let company = companyById(card ? card.companyId : "");
        let property = propertyById(card ? card.propertyId : "");
        let contact = contactById(card ? card.contactId : "");
        let contactName = contact
          ? contact.firstName + " " + contact.lastName
          : "";
        let title = card.title.toLowerCase();
        let companyName = company && company.companyName.toLowerCase();
        let propertyName = property && property.propertyName.toLowerCase();
        let contactNameLowerCase = contactName.toLowerCase();
        let cardNumber = card.cardNumber.toLowerCase();
        let projectAddress = getFullAddress(
          card ? card.projectAddress : {}
        ).toLowerCase();

        if (
          (title || []).includes(searchText) ||
          (cardNumber || []).includes(searchText) ||
          (companyName || []).includes(searchText) ||
          (propertyName || []).includes(searchText) ||
          (contactNameLowerCase || []).includes(searchText) ||
          (projectAddress || []).includes(searchText)
        ) {
          return true;
        }
      });
    }
    if (state.weekFilter.length > 0) {
      newList = newList.filter(card => {
        for (let i = 0; i <= 6; i++) {
          if (
            (toDisplayDateString(card.dueDate) || []).includes(
              state.weekFilter[i]
            )
          ) {
            return true;
          }
        }
      });
    }
    return newList;
  },
  // constructionProject: state => state.constructionProject,
  // constructionProjectList: state => state.constructionProjectList

  constructionActions: state =>
    state.actionList
      .filter(action => action.boards.includes("construction"))
      .sort((a, b) => {
        return a.code - b.code;
      }),

  // Tranfer action ids to action codes
  constructionActionCodeById: (state, getters) => ids => {
    let codes = [];
    ids.forEach(id => {
      const action = getters.constructionActions.find(
        action => action.id === id
      );
      if (action) {
        codes.push(action.code);
      }
    });

    return codes;
  },

  constructionActionIdByCode: (state, getters) => codes => {
    let ids = [];
    codes.forEach(code => {
      const action = getters.constructionActions.find(
        action => action.code === code
      );
      if (action) {
        ids.push(action.id);
      }
    });

    return ids;
  },

  purchaseOrderActionIdByCode: (state, getters) => code => {
    const action = getters.constructionActions.find(
      action => action.code === code
    );
    if (!_.isEmpty(action)) {
      return action.id;
    }

    return "";
  },

  archiveCards: (state, _, __, rootGetters) => {
    const propertyList = rootGetters["dashboard/client/propertyList"];

    const projectResult = state.archiveCardList.map(project => {
      const property =
        propertyList.find(property => property.id === project.propertyId) || {};

      const actionTitle = state.actionList
        .filter(board => (project.actions || []).includes(board.id))
        .map(item => item.title)
        .join(", ");
      return {
        ...project,
        property,
        actionTitle
      };
    });
    return projectResult;
  },
  currActionIdsAfterMoveOrAdd: state => state.currActionIdsAfterMoveOrAdd,

  conditionToDisplayResponseBtn: state => state.conditionToDisplayResponseBtn,
  currentSection: state => state.currentSection
};
