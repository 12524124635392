export default {
  objectList: [],
  viewport: {
    startDate: null,
    endDate: null
  },
  showTimerPopup: false,
  detailUid: null,
  teamFilter: [],
  statusFilter: [],
  timeLogTypeFilter: [],
  projectFilter: null,
  isMyTimeLog: false,
  currentWeek: [],
  timeLogInfoAvailable: {}
};
