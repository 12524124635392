import { FirebaseActions } from "../../../../services/firebase.service";

export default tenant => {
  const WorkOrderActions = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "woTask",
    {
      modelSchema: {
        percentageOfCompletion: "float",
        items: [
          {
            actualQty: "float",
            orderQty: "float"
          }
        ]
      }
    }
  );

  return {
    // bind: WorkOrderActions.bindCollection,
    // bindBy: WorkOrderActions.bindCollectionBy,
    // unbind: WorkOrderActions.unbindCollection,
    // create: WorkOrderActions.createDocument,
    // update: WorkOrderActions.updateDocument,
    // delete: WorkOrderActions.deleteDocument,

    bindWorkOrderTaskBys: WorkOrderActions.bindCollectionBys
  };
};
