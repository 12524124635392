import * as types from "./types";
import Vue from "vue";

export default {
  [types.CHANGE_PROJECT_ITEM](state, { id, item }) {
    const list = state.projectList.map(r => (r.id === id ? item : r));
    Vue.set(state, "projectList", list);
  },

  [types.SET_PROJECT_LIST](state, payload) {
    Vue.set(state, "projectList", payload);
  }
};
