import { sortLatest } from "@/utility/date-time-tool";

export default {
  projectPhotoAlbumList: state => {
    const albumListDefault = (
      state.projectPhotoAlbumList.filter(r => r.createdBy === "By Default") ||
      []
    ).sort((a, b) => a.priority - b.priority);
    const albumListSystem = (
      state.projectPhotoAlbumList.filter(r => r.createdBy === "By System") || []
    ).sort((a, b) => a.priority - b.priority);
    const albumListRest = sortLatest(
      state.projectPhotoAlbumList.filter(
        r => r.createdBy !== "By Default" && r.createdBy !== "By System"
      ) || []
    );
    const albumList = albumListDefault.concat(albumListSystem, albumListRest);
    return albumList.map(r => ({
      ...r,
      photoTotals: r.photoTotals || 0,
      photoLasted: r.photoLasted || {}
    }));
  },
  projectPhotoAlbum: state => state.projectPhotoAlbum
};
