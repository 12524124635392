import { FirebaseActions } from "../../services/firebase.service";
import _ from "lodash";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/administration_user`,
    "user"
  );

  return {
    getUser: ActionsTemplate.getDocument,
    createUser: ActionsTemplate.createDocumentWithCustomeID,
    updateUser: ActionsTemplate.updateDocument,

    addTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (_.isEmpty(user)) {
          const doc = {
            id,
            teamIDs: []
          };
          dispatch("createUser", doc).then(() => {
            dispatch("updateTeamRef", { id, teamID });
          });
        } else {
          dispatch("updateTeamRef", { id, teamID });
        }
      });
    },

    updateTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const teamIDs = _.union(user.teamIDs || [], [teamID]);
          dispatch("updateUser", {
            id,
            doc: { teamIDs }
          });
        }
      });
    },

    removeTeamRef({ dispatch }, { id, teamID }) {
      dispatch("getUser", id).then(user => {
        if (user) {
          const teamIDs = _.remove(user.teamIDs || [], teamId => {
            return teamId != teamID;
          });
          dispatch("updateUser", {
            id,
            doc: { teamIDs }
          });
        }
      });
    }
  };
};
