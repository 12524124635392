import Vue from "vue";
import * as types from "./types";

export default {
  [types.SET_PRICE_LIST](state, payload) {
    Vue.set(state, "priceListItem", payload);
  },

  [types.SET_DEFAULT_PRICE_LIST](state, payload) {
    Vue.set(state, "priceListDefault", payload);
  }
};
