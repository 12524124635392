/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const typeAction = new FirebaseActions(
    `/system_client/${tenant}/app_constant`,
    "type"
  );
  return {
    getConstantList: typeAction.getDocumentBys,

    /**
     * Init constant for the App
     * @param {*} param0
     */
    async initConstant({ dispatch, commit }) {
      const constList = await dispatch("getConstantList");
      commit(types.SET_CONST_TYPE_LIST, constList || []);
    },

    async getDefaultColumns({ dispatch, commit }, tableType) {
      const constList = await dispatch("getConstantList", [
        {
          prop: "code",
          val: "default-column",
          op: "=="
        },
        {
          prop: "tableType",
          val: tableType,
          op: "=="
        }
      ]);
      commit(
        types.SET_DEFAULT_COLUMNS,
        ((constList || [])[0] || {}).value || []
      );
    },

    /**
     * Clear all constant of the App
     * @param {*} param0
     */
    clearConstantList({ commit }) {
      commit(types.SET_CONST_TYPE_LIST, []);
    }
  };
};
