<template>
  <f7-link class="global-search-btn" @click="openPopup">
    <f7-icon color="white" f7="search"></f7-icon>
  </f7-link>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.createTooltip();
  },
  methods: {
    ...mapActions("common/global-search", ["openGlobalSearchPopup"]),
    createTooltip() {
      if (!this.$device.desktop) return;

      this.$f7.tooltip.create({
        targetEl: ".global-search-btn",
        cssClass: "tooltip-fab-button",
        text: "Search infomation"
      });
    },
    openPopup() {
      this.openGlobalSearchPopup(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.global-search-btn {
  position: absolute;
  bottom: var(--global-search-btn-bottom);
  right: var(--global-search-btn-right);
  width: var(--f7-fab-size);
  height: var(--f7-fab-size);
  background-color: var(--f7-theme-color);
  border-radius: 50%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--f7-fab-box-shadow);
}
</style>
