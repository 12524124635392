/* eslint-disable no-unused-vars */
import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";

export default tenant => {
  const ActionsTemplate = new FirebaseActions(
    `/system_client/${tenant}/product_product_items`,
    "productItem"
  );

  return {
    bind: ActionsTemplate.bindCollection,
    bindBy: ActionsTemplate.bindCollectionBy,
    bindBys: ActionsTemplate.bindCollectionBys,
    unbind: ActionsTemplate.unbindCollection,
    create: ActionsTemplate.createDocument,
    update: ActionsTemplate.updateDocument,
    getBys: ActionsTemplate.getDocumentBys,
    // delete: ActionsTemplate.deleteDocument,

    async bindActiveProductItems(
      { rootGetters, commit, dispatch, getters, rootState, state },
      priceListId
    ) {
      const list = await dispatch("getBys", [
        {
          prop: "status",
          val: "pi-active",
          op: "=="
        },
        {
          prop: "priceListRefs",
          val: priceListId,
          op: "array-contains"
        }
      ]);

      commit(types.SET_PRODUCT_LIST, list);
    },

    async getProductItemBys(
      {commit, dispatch},
      conditions
    ) {
      const list = await dispatch("getBys", conditions);

      // commit(types.SET_PRODUCT_ITEM_LIST, list);

      return list
    },
  };

};
