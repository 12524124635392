export default {
  actionList: [],
  cardList: [],
  card: {},
  action: {},
  response: [],

  users: [],
  userFilter: [],
  boardCode: "",
  searchText: "",
  weekFilter: [],
  businessCode: "",

  constructionProjectId: "",
  constructionProjectNumber: "",
  constructionProject: {},
  constructionProjectList: [],
  isDeleteTempleProject: true,
  archiveCardList: [],
  currActionIdsAfterMoveOrAdd: [],

  conditionToDisplayResponseBtn: "",
  currentSection: {}
};
