export default {
  contactList: [],
  companyList: [],
  propertyList: [],

  contact: {},
  company: {},
  property: {},

  insuranceCompany: {},

  contactCompanyRefs: [],
  contactPropertyRefs: [],
  contactProjectRefs: [],

  propertyContactRefs: [],
  propertyCompanyRefs: [],
  propertyProjectRefs: [],

  companyContactRefs: [],
  companyPropertyRefs: [],
  companyBranchRefs: [],
  companyProjectRefs: []
};
