import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_PROJECT](state, data) {
    Vue.set(state, "project", data);
  },

  [types.SET_PROJECT_LIST](state, data) {
    Vue.set(state, "projectList", data);
  }
};
