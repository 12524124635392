import * as types from "./types";
import Vue from "vue";

export default {
  [types.SET_BUILDINGS](state, response) {
    Vue.set(state, "buildings", response);
  },
  [types.SET_INVOICE_LIST](state, response) {
    Vue.set(state, "invoiceList", response);
  },
  [types.SET_INVOICE_GROUP_LIST](state, response) {
    Vue.set(state, "invoiceGroupList", response);
  },
  [types.SET_RECURRING_INVOICE_LIST](state, response) {
    Vue.set(state, "recurringInvoiceList", response);
  }
};
