import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDe_r8fr5lL9eYEYYx4TkXzwIflMfIIF2Q",
  authDomain: "swadleys-appdev.firebaseapp.com",
  databaseUrl: "https://swadleys-appdev.firebaseio.com",
  projectId: "swadleys-appdev",
  storageBucket: "swadleys-appdev.appspot.com",
  messagingSenderId: "292837351740",
  appId: "1:292837351740:web:7681d6c779a91db238be39",
  measurementId: "G-MKNG8BKFHG"
};
const firestore = firebase.initializeApp(firebaseConfig).firestore();

const auth = firebase.auth();
const storage = firebase.storage();

let messaging = null;

if (firebase.messaging.isSupported()) {
   messaging = firebase.messaging();
}

import { firestoreAction } from "vuexfire";
import _ from "lodash";

const loggerEnable = false;

const typeCastObject = modelSchema => model => {
  if (!modelSchema) return model;
  const modelSchemaKeys = Object.keys(modelSchema);
  for (let i = 0; i < modelSchemaKeys.length; i++) {
    const modelSchemaKey = modelSchemaKeys[i];
    const modelSchemaValue = modelSchema[modelSchemaKey];
    if (!modelSchemaValue) continue;
    if (model[modelSchemaKey] === undefined) continue;
    if (typeof modelSchemaValue === "string") {
      model[modelSchemaKey] = typeCast(modelSchemaValue)(model[modelSchemaKey]);
    } else if (Array.isArray(modelSchemaValue)) {
      const arraySchema = modelSchemaValue[0];
      if (!arraySchema) {
        continue;
      }
      const mappingFunc =
        typeof arraySchema === "object" ? typeCastObject : typeCast;
      model[modelSchemaKey] = model[modelSchemaKey].map(x =>
        mappingFunc(arraySchema)(x)
      );
    } else if (typeof modelSchemaValue === "object") {
      model[modelSchemaKey] = typeCastObject(modelSchemaValue)(
        model[modelSchemaKey]
      );
    }
  }
  return model;
};

const typeCast = type => value => {
  if (type === "int") {
    if (!value) return null;
    const numberValue = parseInt(value);
    if (isNaN(numberValue)) return null;

    return numberValue;
  }
  if (type === "float") {
    if (!value) return null;
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return null;

    return numberValue;
  }
  return value;
};

const customSerializer = doc => {
  const data = doc.data();
  // adds _doc property to be used to paginate
  Object.defineProperty(data, "ref", { value: doc });
  // adds id as enumerable property so we can easily access it
  Object.defineProperty(data, "id", { value: doc.id, enumerable: true });
  return data;
};

class FirebaseActions {
  // bindStack = 0;
  constructor(collectionPath, modelName, options) {
    this.collectionPath = collectionPath;
    this.options = options;

    this.bindCollection = firestoreAction((context, limit = 0) => {
      this.logger("bindCollection", limit, context);
      // this.bindStack++;
      return context.bindFirestoreRef(
        `${modelName}List`,
        limit === 0
          ? firestore.collection(collectionPath)
          : firestore
              .collection(collectionPath)
              .orderBy("createdAt", "desc")
              .limit(limit)
      );
    });

    this.subcribeCollection = firestoreAction((_, callback) => {
      return firestore.collection(collectionPath).onSnapshot(
        snapshot => {
          if (callback) callback(snapshot.docChanges());
        },
        error => {
          this.logger("subcribeCollection", error);
        }
      );
    });

    this.bindFirstDocumentBy = firestoreAction((context, { prop, val, op }) => {
      this.logger("bindFirstDocumentBy", { prop, val, op }, context);

      const ref = firestore
        .collection(collectionPath)
        .where(prop, op, val)
        .orderBy("createdAt", "desc")
        .limit(1);

      return context.bindFirestoreRef(`${modelName}List`, ref);
    });

    this.bindCollectionBy = firestoreAction((context, { prop, val, op }) => {
      this.logger("bindCollectionBy", { prop, val, op }, context);

      const ref = firestore.collection(collectionPath).where(prop, op, val);
      // this.bindStack++;
      return context.bindFirestoreRef(`${modelName}List`, ref);
    });

    this.bindCollectionBys = firestoreAction((context, andConditions = []) => {
      this.logger("bindCollectionBys", andConditions, context);

      let ref = firestore.collection(collectionPath);
      andConditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });
      // this.bindStack++;
      return context.bindFirestoreRef(`${modelName}List`, ref);
    });

    this.unbindCollection = firestoreAction(context => {
      this.logger("unbindCollection", {});
      // this.bindStack--;
      // if (this.bindStack) return;
      return context.unbindFirestoreRef(`${modelName}List`);
    });

    this.bindDocument = firestoreAction((context, id) => {
      this.logger("bindDocument", id);
      return context.bindFirestoreRef(
        `${modelName}`,
        firestore.collection(collectionPath).doc(id),
        {
          reset: false
        }
      );
    });

    this.unbindDocument = firestoreAction(context => {
      this.logger("unbindDocument", {});
      return context.unbindFirestoreRef(`${modelName}`);
    });

    this.getMorePage = firestoreAction(
      (context, { limit, order = "asc", andConditions = [] }) => {
        let ref = firestore.collection(collectionPath);
        andConditions.forEach(condition => {
          ref = ref.where(condition.prop, condition.op, condition.val);
        });
        ref = ref.orderBy("createdAt", order).limit(limit);

        const lastItemRef = context.state[`${modelName}LastRef`];
        if (lastItemRef) {
          ref = ref.startAfter(lastItemRef);
        }

        return ref
          .get()
          .then(snaps => {
            const docs = [];

            if (!snaps.empty) {
              for (const snap of snaps.docs) {
                let doc = snap.data();
                // adds _doc property to be used to paginate
                Object.defineProperty(doc, "ref", { value: snap });
                // adds id as enumerable property so we can easily access it
                Object.defineProperty(doc, "id", {
                  value: snap.id,
                  enumerable: true
                });
                docs.push(doc);
              }
            }
            return docs;
          })
          .then(data => {
            if (data.length > 0) {
              context.commit("MERGE_DATA_BATCH", { data });
              context.commit("SET_LAST_ITEM_REF", {
                ref: _.cloneDeep(data[data.length - 1].ref)
              });
            }
            // set all loaded if we dont return as many as the limit
            if (data.length < limit) context.commit("ALL_DATA_LOADED");
          });
      }
    );

    this.bindNextPage = firestoreAction(
      (context, { limit, order = "asc", andConditions = [] }) => {
        let currentPageNumber =
          context.state[`${modelName}CurrentPageNumber`] || 0;
        const currentPage =
          context.state[`${modelName}Pages`].find(
            item => item.pageNumber === currentPageNumber
          ) || {};

        if (currentPage.isLastPage) {
          return;
        }

        let ref = firestore.collection(collectionPath);
        andConditions.forEach(condition => {
          ref = ref.where(condition.prop, condition.op, condition.val);
        });

        ref = ref.orderBy("createdAt", order).limit(limit);

        if (currentPage.lastItemRef) {
          ref = ref.startAfter(currentPage.lastItemRef);
        }

        return context
          .bindFirestoreRef(`${modelName}List`, ref, {
            serialize: customSerializer
          })
          .then(data => {
            currentPageNumber += 1;
            context.commit("PAGINATION_SET_CURRENT_PAGE_NUMBER", {
              key: `${modelName}CurrentPageNumber`,
              value: currentPageNumber
            });

            let firstItem = null;
            let lastItem = null;
            let isLastPage = true;

            if (data.length > 0) {
              firstItem = _.cloneDeep(data[0].ref) || null;
              lastItem = _.cloneDeep(data[data.length - 1].ref) || null;
              isLastPage = data.length === 0 || data.length < limit;
            }

            context.commit("PAGINATION_SET_PAGE", {
              key: `${modelName}Pages`,
              value: {
                pageNumber: currentPageNumber,
                isLastPage: isLastPage,
                firstItemRef: firstItem,
                lastItemRef: lastItem
              }
            });
          });
      }
    );

    this.bindPrevPage = firestoreAction(
      (context, { limit, order = "asc", andConditions = [] }) => {
        let currentPageNumber =
          context.state[`${modelName}CurrentPageNumber`] || 0;

        if (currentPageNumber === 0 || currentPageNumber === 1) {
          return;
        }

        const prevPage =
          context.state[`${modelName}Pages`].find(
            item => item.pageNumber === currentPageNumber - 1
          ) || {};

        let ref = firestore.collection(collectionPath);
        andConditions.forEach(condition => {
          ref = ref.where(condition.prop, condition.op, condition.val);
        });

        ref = ref.orderBy("createdAt", order).limitToLast(limit);

        if (prevPage.lastItemRef) {
          ref = ref.endAt(prevPage.lastItemRef);
        }

        return context
          .bindFirestoreRef(`${modelName}List`, ref, {
            serialize: customSerializer
          })
          .then(() => {
            currentPageNumber -= 1;
            context.commit("PAGINATION_SET_CURRENT_PAGE_NUMBER", {
              key: `${modelName}CurrentPageNumber`,
              value: currentPageNumber
            });
          });
      }
    );

    this.getDocument = firestoreAction((context, id) => {
      this.logger("getDocument", id);

      return firestore
        .collection(collectionPath)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let doc = snap.data();
            doc.id = snap.id;
            return doc;
          }
        });
    });

    this.getDocumentBys = firestoreAction((context, andConditions = []) => {
      this.logger("getDocumentBys", andConditions);
      let ref = firestore.collection(collectionPath);
      andConditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });

      return ref.get().then(snaps => {
        const docs = [];

        if (!snaps.empty) {
          for (const snap of snaps.docs) {
            let doc = snap.data();
            doc.id = snap.id;
            docs.push(doc);
          }
        }

        return docs;
      });
    });

    this.getDocumentAndLimitBys = firestoreAction(
      (context, { andConditions = [], limit = 0 }) => {
        this.logger("getDocumentAndLimitBys", andConditions);

        let ref = firestore.collection(collectionPath);
        andConditions.forEach(condition => {
          ref = ref.where(condition.prop, condition.op, condition.val);
        });

        if (limit > 0) {
          ref = ref.orderBy("createdAt", "desc").limit(limit);
        }

        return ref.get().then(snaps => {
          const docs = [];

          if (!snaps.empty) {
            for (const snap of snaps.docs) {
              let doc = snap.data();
              doc.id = snap.id;
              docs.push(doc);
            }
          }

          return docs;
        });
      }
    );

    this.getDocumentSizeBys = firestoreAction((context, andConditions = []) => {
      this.logger("getDocumentSizeBys", andConditions);

      let ref = firestore.collection(collectionPath);
      andConditions.forEach(condition => {
        ref = ref.where(condition.prop, condition.op, condition.val);
      });

      return ref.get().then(snaps => {
        return snaps.size;
      });
    });

    this.createDocument = firestoreAction((context, doc) => {
      this.logger("createDocument", doc);

      doc.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      doc.createdBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : "";
      doc.createdById = auth.currentUser ? auth.currentUser.uid : "";
      doc.id = "";

      if (this.options && this.options.modelSchema) {
        doc = typeCastObject(this.options.modelSchema)(doc);
      }
      return firestore
        .collection(collectionPath)
        .add(doc)
        .then(result => {
          return this.updateDocument(context, {
            id: result.id,
            doc: { id: result.id, isDeleted: false }
          });
        });
    });

    this.createDocumentWithCustomeID = firestoreAction((context, doc) => {
      this.logger("createDocumentWithCustomeID", doc);
      doc.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      doc.createdBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : "";
      if (this.options && this.options.modelSchema) {
        doc = typeCastObject(this.options.modelSchema)(doc);
      }
      return firestore
        .collection(collectionPath)
        .doc(doc.id)
        .set(doc);
    });

    this.updateDocument = firestoreAction((context, { id, doc }) => {
      this.logger("updateDocument", { id, doc });

      doc.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      doc.updatedBy = auth.currentUser
        ? auth.currentUser.displayName || auth.currentUser.email
        : "";

      if (this.options && this.options.modelSchema) {
        doc = typeCastObject(this.options.modelSchema)(doc);
      }

      return firestore
        .collection(collectionPath)
        .doc(id)
        .update(doc)
        .then(() => id);
    });

    this.deleteDocument = firestoreAction((context, id) => {
      this.logger("deleteDocument", id);

      return firestore
        .collection(collectionPath)
        .doc(id)
        .delete();
    });

    this.modifyRelationShip = firestoreAction(
      (context, { id, action, doc }) => {
        this.logger("modifyRelationShip", { id, action, doc });
        return firestore
          .collection(collectionPath)
          .doc(id)
          .get()
          .then(snapshot => {
            let refIDs = [];
            if (snapshot.exists) {
              refIDs = snapshot.data()[doc.key] || [];

              let newRefIDs = [];
              switch (action) {
                case "add":
                  newRefIDs = _.union(refIDs, [doc.id]);
                  break;

                case "remove":
                  newRefIDs = _.remove(refIDs, id => {
                    return id != doc.id;
                  });
                  break;

                default:
                  newRefIDs = refIDs;
                  break;
              }

              if (!_.isEqual(_.sortBy(newRefIDs), _.sortBy(refIDs))) {
                doc.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                doc.updatedBy = auth.currentUser
                  ? auth.currentUser.displayName || auth.currentUser.email
                  : "";
                return firestore
                  .collection(collectionPath)
                  .doc(id)
                  .update({
                    [doc.key]: newRefIDs
                  });
              }
            }
            return refIDs;
          });
      }
    );

    this.logger = (methodName, obj, context) => {
      if (process.env.NODE_ENV === "development" && loggerEnable === true) {
        const date = new Date();
        // eslint-disable-next-line no-console
        console.log(
          `[VUEXFIRE] [${methodName}] [${date.toLocaleTimeString()}.${date.getMilliseconds()}]`,
          collectionPath,
          JSON.parse(JSON.stringify(obj))
        );
        const logContext = context
          ? // eslint-disable-next-line no-console
            () => console.log("[context]", context)
          : () => {};

        logContext();
      }
    };

    this.timer = (methodName, dateTime) => {
      if (process.env.NODE_ENV === "development" && loggerEnable === true) {
        const date = new Date();
        const diff = date - dateTime;
        // eslint-disable-next-line no-console
        console.log(
          `[VUEXFIRE] [${methodName}] [${diff}]`,
          collectionPath,
          diff
        );
      }
    };
  }

  static getCollection(path) {
    return firestore
      .collection(path)
      .get()
      .then(snaps => {
        let docs = [];
        snaps.forEach(docSnap => {
          let doc = docSnap.data();
          doc.id = docSnap.id;
          docs.push(doc);
        });
        return docs;
      });
  }

  static getCollectionWithConditions(path, conditions = []) {
    let query = firestore.collection(path);
    conditions.forEach(condition => {
      query = query.where(condition.prop, condition.op, condition.val);
    });
    return query.get().then(snaps => {
      let docs = [];
      snaps.forEach(docSnap => {
        let doc = docSnap.data();
        doc.id = docSnap.id;
        docs.push(doc);
      });
      return docs;
    });
  }

  static getDocument(path, docId) {
    return firestore
      .collection(path)
      .doc(docId)
      .get()
      .then(snap => {
        let doc = snap.data();
        doc.id = snap.id;
        return doc;
      });
  }
}

const uploadFile = async (fullPath, file) => {
  const metadata = {
    contentType: file.type
  };
  return storage
    .ref(fullPath)
    .put(file, metadata)
    .then(snapshot => snapshot.ref.getDownloadURL())
    .then(url => {
      return url;
    });
};

const removeFile = async fullPath => {
  try {
    await storage.ref(fullPath).getMetadata();
    await storage.ref(fullPath).delete();
    return `File at ${fullPath} deleted successfully.`;
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      return `File at ${fullPath} does not exist.`;
    }
    throw error;
  }
};

const getMessagingToken = async () => {
  let currentToken = "";

  if (!messaging) return;
  if (!auth.currentUser) return;
  try {
    const newSw = await navigator.serviceWorker.register(
      "/firebase-messaging-sw.js"
    );
    currentToken = await messaging.getToken({
      vapidKey: import.meta.env.VITE_FIREBASE_FCM_VAPID_KEY,
      serviceWorkerRegistration: newSw
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("An error occurred while retrieving token. ", error);
  }
  return currentToken;
};
const removeMessagingToken = async () => {
  if (!messaging) return;
  try {
    await messaging.deleteToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("An error occurred while deleting the token. ", error);
  }
};

const setupOnMessageListener = onReceiveMessage => {
  messaging.onMessage(payload => {
    onReceiveMessage(payload);
  });
};

class StorageActions {
  static getDownloadURL = async fullPath => {
    return storage.ref(fullPath).getDownloadURL();
  };
  //upload file with base64
  static uploadFileBase64 = (fullPath, base64, meta) => {
    const metadata = {
      contentType: meta.type
    };

    return storage
      .ref(fullPath)
      .putString(base64, firebase.storage.StringFormat.DATA_URL, metadata)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL().then(url => {
          return { fullPath: snapshot.ref.fullPath, url: url };
        });
      });
  };
  //copy file
  static copyFile = async (fullPath, toPath) => {
    let fullPathUrl = "";
    try {
      fullPathUrl = await storage.ref(fullPath).getDownloadURL();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("[copyFile]", error.message);
    }

    if (fullPathUrl) {
      const response = await fetch(fullPathUrl);
      const base64 = await response.blob();

      const snapshot = await storage.ref(toPath).put(base64);
      const imageUrl = await snapshot.ref.getDownloadURL();

      return { fullPath: snapshot.ref.fullPath, url: imageUrl };
    }

    return {};
  };
  //upload file
  static uploadFile = async (fullPath, file) => {
    const metadata = {
      contentType: file.type
    };
    return storage
      .ref(fullPath)
      .put(file, metadata)
      .then(snapshot => {
        return snapshot.ref.getDownloadURL().then(url => {
          return { fullPath: snapshot.ref.fullPath, url: url };
        });
      });
  };

  static removeFile = async fullPath => {
    return storage.ref(fullPath).delete();
  };

  static downloadFile = async ({ fullPath, name, photoUrl }) => {
    if (!fullPath) return;

    let url;
    if (photoUrl !== "") {
      url = photoUrl;
    } else {
      url = await storage.ref(fullPath).getDownloadURL();
    }

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const blob = xhr.response;
      const link = document.createElement("a");
      link.setAttribute("href", URL.createObjectURL(blob));
      link.setAttribute("download", fullPath.split("/").slice(-1)[0] || name);
      link.click();
      link.remove();
    };
    xhr.open("GET", url);
    xhr.send();
  };

  static removeFolderContents = async path => {
    const parentRef = storage.ref().child(path);
    return parentRef
      .listAll()
      .then(result => {
        result.items.forEach(file => {
          file.delete();
        });
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };
}

export {
  firebaseConfig,
  firebase,
  firestore,
  auth,
  storage,
  FirebaseActions,
  getMessagingToken,
  removeMessagingToken,
  setupOnMessageListener,
  uploadFile,
  removeFile,
  StorageActions
};
