export default {
  objectList: [],
  detailId: null,
  filter: { title: null },

  userFilter: [],
  searchText: "",
  weekFilter: [],
  currentProject: {}
};
