import { checkAuth } from "../../../services/authentication.service";
import InvoiceClientViewPage from "../pages/InvoiceClientViewPage.vue";

function routeList() {
  let routes = [];
  const childrenInvoiceRoutes = [
    // new invoice from commercial board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-commercial",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },

    // residential
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-residential",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },

    // new invoice from estimate commercial board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/from-estimate/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-from-estimate-swimlane-commercial",
      async: checkAuth(() =>
        import("../components/popups/NewInvoiceFromEstimatePopup.vue")
      )
    },

    // new invoice from residential board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/action/:actionId/project/:projectId/contact/:contactId/estimate/:actionType/", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-residential-from-estimate",
      async: checkAuth(() =>
        import("../components/popups/NewInvoiceFromEstimatePopup.vue")
      )
    },
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/action/:actionId/project/:projectId/contact/:contactId/template/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-residential-from-template",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },

    // new invoice from construction board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/board/:boardId/project/:projectId/action/:actionId/task/:taskId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-construction",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    // new invoice from estimate from construction board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/from-estimate/:boardType/business/:businessCode/board/:boardId/project/:projectId/action/:actionId/task/:taskId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-from-estimate-swimlane-construction",
      async: checkAuth(() =>
        import("../components/popups/NewInvoiceFromEstimatePopup.vue")
      )
    },

    // new invoice from swimlane board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane-service",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    // new invoice from estimate from swimlane board
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/from-estimate/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-from-estimate-swimlane-service",
      async: checkAuth(() =>
        import("../components/popups/NewInvoiceFromEstimatePopup.vue")
      )
    },
    //from residental
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-swimlane",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    //from estimate from residental
    {
      displayRoles: ["owner", "user"],
      checkAccessPath: "finance-main/invoices",
      path:
        "/dashboard/from-estimate/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/:actionType", // actionType is new to open popup add new
      title: "New Invoice",
      name: "new-invoice-from-estimate-swimlane",
      async: checkAuth(() =>
        import("../components/popups/NewInvoiceFromEstimatePopup.vue")
      )
    },
    {
      path: "/invoices-group/invoice/new-invoice",
      name: "invoices-new",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "New Invoice",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    {
      path: "/invoices-group/recurring-invoice/new-recurring-invoice",
      name: "recurring-invoices-new",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "New Recurring Invoice",
      async: checkAuth(() => import("../pages/NewRecurringInvoicePage.vue"))
    },
    {
      path: "/invoices-group/:invoiceGroupId/invoice/new-invoice",
      name: "invoices-new",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "New Invoice",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    {
      path: "/invoices-group/:invoiceGroupId/recurring-invoice/new-recurring-invoice",
      name: "recurring-invoices-new",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "New Recurring Invoice",
      async: checkAuth(() => import("../pages/NewRecurringInvoicePage.vue"))
    },
    {
      path: "/invoices-group/:invoiceGroupId/invoice/:invoiceNumber",
      checkAccessPath: "finance-main/invoices",
      title: "Invoice Detail",
      name: "detail-list",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      async: checkAuth(() => import("../pages/InvoiceGroupDetailPage.vue"))
    },
    {
      path: "/invoices-group/:invoiceGroupId/invoice/edit/:invoiceNumber",
      name: "invoices-edit",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "Edit Invoice",
      async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
    },
    {
      path:
        "/invoices-group/:invoiceGroupId/recurring-invoice/edit/:recurringInvoiceNumber",
      name: "recurring-invoices-new",
      checkAccessPath: "finance-main/invoices",
      displayRoles: ["owner", "user"],
      icon: "doc_text",
      title: "New Recurring Invoice",
      async: checkAuth(() => import("../pages/NewRecurringInvoicePage.vue"))
    },
  ];
  if (localStorage.getItem("desktop") === "true") {
    routes.push({
      path: "",
      title: "Invoices",
      name: "invoices-main",
      displayRoles: ["owner", "user"],
      routes: [
        {
          path: "/invoices-group",
          title: "Invoices",
          name: "invoices",
          parent: "finance-main",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          async: checkAuth(() => import("../pages/InvoicePage.vue"))
        },
        {
          path: "/invoices-group/invoice/new-invoice",
          name: "invoices-new",
          checkAccessPath: "finance-main/invoices",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          title: "New Invoice",
          async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
        },
        {
          path: "/invoices-group/:invoiceGroupId/invoice/new-invoice",
          name: "invoices-new",
          checkAccessPath: "finance-main/invoices",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          title: "New Invoice",
          async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
        },
        {
          path: "/invoices-group/:invoiceGroupId/invoice/:invoiceNumber",
          checkAccessPath: "finance-main/invoices",
          title: "Invoice Detail",
          name: "detail-list",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          async: checkAuth(() => import("../pages/InvoiceGroupDetailPage.vue"))
        },
        {
          path: "/invoices-group/:invoiceGroupId/invoice/edit/:invoiceNumber",
          name: "invoices-edit",
          checkAccessPath: "finance-main/invoices",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          title: "Edit Invoice",
          async: checkAuth(() => import("../pages/NewInvoicePage.vue"))
        },

        {
          path: "/recurring-invoices",
          title: "Invoices",
          name: "recurring-invoices",
          displayRoles: ["owner", "user"],
          checkAccessPath: "finance-main/invoices",
          icon: "doc_plaintext",
          async: checkAuth(() =>
            import("../pages/RecurringInvoiceListPage.vue")
          ),
          master: true,
          detailRoutes: [
            {
              displayRoles: ["owner", "user"],
              checkAccessPath: "finance-main/invoices",
              path: "/recurring-invoices/:id",
              async: checkAuth(() =>
                import("../pages/RecurringInvoiceDetailPage.vue")
              )
            },
            {
              displayRoles: ["owner", "user"],
              checkAccessPath: "finance-main/invoices",
              path: "/recurring-invoices/:recurringInvoiceId/:invoiceNumber",
              async: checkAuth(() => import("../pages/InvoiceDetailPage.vue"))
            }
          ]
        },
        ...childrenInvoiceRoutes
      ]
    });
  } else {
    routes.push({
      path: "",
      title: "Invoices",
      name: "invoices-main",
      displayRoles: ["owner", "user"],
      routes: [
        {
          path: "/invoices-group",
          title: "Invoices",
          name: "invoices",
          parent: "finance-main",
          displayRoles: ["owner", "user"],
          icon: "doc_text",
          async: checkAuth(() => import("../pages/InvoicePage.vue"))
        },
        ...childrenInvoiceRoutes
      ]
    });
    routes.push({
      path: "",
      title: "Recurring Invoices",
      name: "recurring-invoices-main",
      displayRoles: ["owner", "user"],
      disableGrantMenu: true,
      routes: [
        {
          path: "/recurring-invoices",
          title: "Recurring Invoices",
          name: "invoices",
          parent: "finance-main",
          disableGrantMenu: true,
          displayRoles: ["owner", "user"],
          checkAccessPath: "finance-main/invoices",
          icon: "doc_plaintext",
          async: checkAuth(() =>
            import("../pages/RecurringInvoiceListPage.vue")
          ),
          master: true,
          detailRoutes: [
            {
              path: "/recurring-invoices/:id",
              displayRoles: ["owner", "user"],
              checkAccessPath: "finance-main/invoices",
              async: checkAuth(() =>
                import("../pages/RecurringInvoiceDetailPage.vue")
              )
            },
            {
              displayRoles: ["owner", "user"],
              checkAccessPath: "finance-main/invoices",
              path: "/recurring-invoices/:recurringInvoiceId/:invoiceNumber",
              async: checkAuth(() => import("../pages/InvoiceDetailPage.vue"))
            }
          ]
        }
      ]
    });
  }
  routes.push({
    path: "",
    title: "Invoices-view",
    name: "invoices-view",
    routes: [
      {
        path: "/invoices-view",
        title: "Invoices",
        name: "invoices",
        icon: "doc_text",
        component: InvoiceClientViewPage
      }
    ]
  });
  return routes;
}

export default routeList();
