import { FirebaseActions, firebase } from "../../services/firebase.service";
import notificationService from "../../services/notification.service";
import * as types from "./types";
import _ from "lodash";
const auth = firebase.auth();

export default tenant => {
  const notificationsActions = new FirebaseActions(
    `/system_client/${tenant}/notifications`,
    "notifications"
  );
  const tokenUserActions = new FirebaseActions(
    `/system_client/${tenant}/user_Tokens`,
    "userTokens"
  );
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    "project"
  );

  const boardActions = new FirebaseActions(
    `/system_client/${tenant}/swimlane_board`,
    "board"
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "action"
  );
  return {
    unbindNotificationList: notificationsActions.unbindCollection,
    createNotification: notificationsActions.createDocument,
    getMoreNotification: notificationsActions.getMorePage,
    getNotificationListBys: notificationsActions.getDocumentBys,
    getNotificationBys: notificationsActions.getDocument,
    updateNotification: notificationsActions.updateDocument,
    addNewUserToken: tokenUserActions.createDocument,
    updateUserToken: tokenUserActions.updateDocument,
    getUserTokenListBys: tokenUserActions.getDocumentBys,
    getProjectById: projectActions.getDocument,
    getBoardById: boardActions.getDocument,
    getActionById: actionActions.getDocument,
    async getTokenListBys({ commit, dispatch }, value) {
      const userTokenList = await dispatch("getUserTokenListBys", [
        {
          prop: "userId",
          val: value,
          op: "=="
        }
      ]);
      await commit(types.SET_USER_TOKEN_LIST, userTokenList || []);
      return userTokenList;
    },
    async updateAllNotification({ commit, dispatch, rootGetters }) {
      const notifyListByUser = rootGetters["common/notification/notifyListByUser"];

      const updatePromises = notifyListByUser.map(notify =>
        dispatch("updateNotification", {
          id: notify.id,
          doc: { isRead: true }
        })
      );
      await dispatch("getNotificationListByUser");
      await Promise.all(updatePromises).then(() => {
        commit(types.UPDATE_NOTIFICATION_LIST);
      });
    },
    async getNotificationListByUser({rootGetters, dispatch, commit }) {
      const user = rootGetters["setting/app/profile/user"];
      const notifyListByUser = await dispatch("getNotificationListBys", [
        { prop: "isRead", val: false, op: "==" },
        { prop: "to", val: user.uid, op: "==" }
      ]);
      commit(types.NOTIFICATION_LIST, notifyListByUser);
    },
    async updateNotificationBys({ commit, dispatch }, value) {
      const notify = await dispatch("getNotificationBys", value);
      await dispatch("updateNotification", {
        id: notify.id,
        doc: { isRead: true }
      }).then(async () => {
        await dispatch("getNotificationListByUser");
        commit(types.UPDATE_NOTIFICATION_LIST, value);
      });
    },
    resetNotificationPage({ commit }) {
      commit(types.RESET_PAGE);
    },
    async createNotificationByType({ dispatch }, { data, type }) {
      if (!data.assignees || data.assignees.length === 0) {
        return;
      }
      let assignees = _.cloneDeep(data.assignees).filter(id => id !== auth.currentUser.uid);
      let results = [];
      switch (type) {
        case "assign-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "assign-user",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has assigned you ${
                data.managerID
                  ? assignee === data.managerID
                    ? "as a manager of" + data.project.type
                    : "to " + data.project.type
                  : "to " + data.project.type
              } <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "move-project": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "move-project",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has moved ${data.project.type} <strong>${data.project.title}</strong> to ${data.project.to} board ${data.project.subTitle ? (data.project.subTitle) : ""}</div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "remove-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "remove-user",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has removed you from ${data.project.type} <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "note": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "note-comment",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has left a note(comment) in your project.</div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "resolve-action": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "resolve-action",
              message: `<div><strong>${auth.currentUser.displayName}</strong> resolved project <strong>${data.project.title}</strong> to <strong>${data.project.nextTitle}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "remove-manager": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "remove-manager",
              message: `<div>You are no longer the manager of team <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "restore-project": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "restore-project",
              message: `<div><strong>${auth.currentUser.displayName}</strong> restore your project: <strong>${data.project.title} from archive board</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "active-team": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "active-team",
              message: `<div>${
                data.project.type === "team" ? "Team" : "Group"
              } <strong>${data.project.title}</strong> is ${
                data.disabled ? "inactivated" : "activated"
              } by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "change-name": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "change-name",
              message: `<div>${
                data.project.type === "team" ? "Team" : "Group"
              } <strong>${data.oldName}</strong> has been renamed to <strong>${
                data.project.title
              }</strong>by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "add-new-shift-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "add-new-shift-user",
              message: `<div>You have been added new shift on scheduling by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              startDate: data.project.startDate,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "delete-shift-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "delete-shift-user",
              message: `<div>Your shift has been deleted by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "approve-timelog-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "approve-timelog-user",
              message: `<div>You have a timelog that has been approved by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false
            };
            results.push(result);
          }
          break;
        }
        case "reject-timelog-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "reject-timelog-user",
              message: `<div>You have a timelog that has been rejected by <strong>${auth.currentUser.displayName}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "add-timelog-user": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "add-timelog-user",
              message: `<div>You have a new timelog just added by admin</div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "add-timelog-admin": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "add-timelog-admin",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has created a timelog and is waiting for your approval</div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "add-price-list": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "add-price-list",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has created a new price list</div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "remove-price-list": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "remove-price-list",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has deleted price list: <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "remove-product": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "remove-product",
              message: `<div><strong>On ${data.priceList}: ${auth.currentUser.displayName}</strong> has deleted product <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "copy-product":
        case "edit-product": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: type,
              message: `<div><strong>On ${data.priceList}: ${auth.currentUser.displayName}</strong> has ${type === "edit-product" ? 'edited' : 'copied'} product <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "add-product": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "add-product",
              message: `<div><strong>On ${data.priceList}: ${auth.currentUser.displayName}</strong> has changes some product</div>`,
              to: assignee,
              projectId: data.project.id,
              isRead: false,
              projectName: data.project.title,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "review-product": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "review-product",
              message: `<div><strong>Price list ${data.priceList} has a product for you to review</div>`,
              to: assignee,
              projectId: data.project.id,
              isRead: false,
              projectName: data.project.title,
              entityName: data.project.entityName,
              priceListNumber: data.project.priceListNumber
            };
            results.push(result);
          }
          break;
        }
        case "delete-proposal": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "delete-proposal",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has deleted your proposal: <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectName: data.project.title,
              projectId: data.project.id,
              isRead: false,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }
        case "edit-proposal": {
          for (const assignee of assignees) {
            const result = {
              from: auth.currentUser ? auth.currentUser.uid : "",
              activityType: "edit-proposal",
              message: `<div><strong>${auth.currentUser.displayName}</strong> has edited your proposal: <strong>${data.project.title}</strong></div>`,
              to: assignee,
              projectId: data.project.id,
              isRead: false,
              proposalNumber: data.project.title,
              entityName: data.project.entityName
            };
            results.push(result);
          }
          break;
        }

        default:
          // eslint-disable-next-line no-console
          console.log("Unknown notification data");
          return;
      }
      Promise.all(
        results.map(result =>
          dispatch("createNotification", result).then(() => {
            notificationService.pushNotification(result);
          })
        )
      ).catch(error => {
        // eslint-disable-next-line no-console
        console.error("An error occurred:", error);
      });
    },
    openGlobalNotifyPopup({ commit }, payload) {
      commit(types.SHOW_GLOBAL_NOTIFY_POPUP, payload);
    }
  };
};
