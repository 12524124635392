import _ from "lodash";
export default {
  notificationsList: state => {
    return _.cloneDeep(state.notificationsList).sort((a, b) => {
      return a.createdAt && b.createdAt
        ? b.createdAt.seconds - a.createdAt.seconds
        : -1;
    });
  },
  notifyListByUser: state => {
    return _.cloneDeep(state.notifyListByUser).sort((a, b) => {
      return a.createdAt && b.createdAt
        ? b.createdAt.seconds - a.createdAt.seconds
        : -1;
    });
  },
  notifications: state => state.notifications,
  userTokenList: state => state.userTokenList,
  showGlobalNotifyPopup: state => state.showGlobalNotifyPopup
};
