import { auth } from "./firebase.service";
import axios from "./axios.service";
import { firestore } from "./firebase.service";
import moment from "moment";
import { app } from "../main";
// import _ from "lodash";
const loggerEnable = false;

const signIn = (loginTenantId, email, password) => {
  if (loginTenantId && loginTenantId !== "") {
    auth.tenantId = loginTenantId;
  }

  return auth
    .signInWithEmailAndPassword(email, password)
    .then(data => {
      return data.user;
    })
    .catch(error => {
      if (
        error.code === "auth/wrong-password" ||
        error.code === "auth/user-not-found"
      ) {
        throw {
          code: error.code,
          message: "Invalid password or the user does not exist."
        };
      }
      throw { code: error.code, message: error.message };
    });
};

const signOut = () => {
  const loginTenantId = localStorage.getItem("login_tenant");
  if (loginTenantId && loginTenantId !== "") {
    auth.tenantId = loginTenantId;
  }
  return auth
    .signOut()
    .then(() => {
      removeToken();
      return;
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error("[signOut]", error.code, error.message);
    });
};

// eslint-disable-next-line no-unused-vars
const checkAuth = loadComponentFunc => (
  routeTo,
  routeFrom,
  resolve,
  // eslint-disable-next-line no-unused-vars
  reject
) => {
  if (process.env.NODE_ENV === "development" && loggerEnable === true) {
    const date = new Date();
    // eslint-disable-next-line no-console
    console.log(
      `[AUTH] Checking authentication... ${date.toLocaleTimeString()}.${date.getMilliseconds()}`
    );
  }

  auth.onAuthStateChanged(async user => {
    if (user && user.emailVerified === true) {
      const setting = await getSetting(user.tenantId);
      if (setting.isAccountClosed === true) {
        removeToken();
        window.location.href = "/login";
      }
      saveIDToken(user);
      let allow = await checkPermission({ routeTo, user });
      if (!allow) {
        window.location.href = "/permission-denied";
        // routeTo.url = `/permission-denied`;
        // const permissionDenied = () => import("../pages/PermissionDenied.vue");
        // return permissionDenied().then(vc => {
        //   return resolve({ component: vc.default });
        // });
      }

      // TODO: Disable check subscription for more discussion of payment feature
      // if (routeTo.path !== "/administration/subscription") {
      //   await checkSubscriptionPlan(user, setting.subscription);
      // }

      return loadComponentFunc(routeTo).then(vc => {
        // resolve with component
        return resolve({ component: vc.default });
      });
    } else if (user && user.emailVerified === false) {
      routeTo.url = `/verify-email`;
      const verifyEmailFunc = () => import("../pages/VerifyEmail.vue");
      return verifyEmailFunc().then(vc => {
        removeToken();
        return resolve(
          { component: vc.default },
          { pushState: true, reloadAll: true, master: false }
        );
      });
    } else {
      removeToken();
      window.location.href = "/login";
      // routeTo.url = `/login`;
      // const component = () => import("../pages/LoginPage.vue");
      // return component().then(vc => {
      //   return resolve(
      //     { component: vc.default },
      //     { pushState: true, reloadAll: true, master: false }
      //   );
      // });
    }
  });
};
const checkPermission = async ({ user, routeTo }) => {
  let userInfo = await user.getIdTokenResult();
  // allow all for role owner
  if (userInfo.claims && userInfo.claims.role === "owner") return true;
  //check if user have role permission
  let allowRole = checkRole({ role: userInfo.claims.role, routeTo });
  if (!allowRole) {
    return false;
  }
  //check if user have group permission
  let allowGroup = await checkGroup({
    groupIDs: userInfo.claims.groupIDs,
    routeTo,
    tenantId: user.tenantId
  });
  if (!allowGroup) {
    return false;
  }
  return true;
};

// eslint-disable-next-line no-unused-vars
const checkSubscriptionPlan = async (user, currentSubscription) => {
  // get failed payment
  const failedInvoices = await getFailInvoices();
  let userInfo = await user.getIdTokenResult();
  const currentDate = moment(new Date(), "MM/DD/YYYY");
  const dueDate = moment(
    currentSubscription.paymentExpired.toDate(),
    "MM/DD/YYYY"
  );
  const secondsDiff = dueDate.diff(currentDate, "seconds");
  const daysDiff = secondsDiff / 60 / 60 / 24;
  if (secondsDiff < 0) {
    // expired subscription plan
    if (userInfo.claims && userInfo.claims.role === "owner") {
      window.location.href = "/administration/subscription";
    } else {
      window.location.href = "/permission-denied";
    }
  } else if (
    userInfo.claims &&
    userInfo.claims.role === "owner" &&
    secondsDiff > 0 &&
    failedInvoices.length > 0
  ) {
    // upgrade to year plan but not paid
    window.location.href = "/administration/subscription";
  } else if (
    // reminder coming up renewal date
    userInfo.claims &&
    userInfo.claims.role === "owner" &&
    secondsDiff > 0 &&
    daysDiff <= 10 &&
    localStorage.getItem("is_remind_subscription_renew") !== "false" &&
    failedInvoices.length < 1
  ) {
    app.$f7.notification
      .create({
        icon: "<i class='color-yellow icon f7-icons'>bell_fill</i>",
        title: "Notification",
        text: `<b>${moment(currentSubscription.paymentExpired.toDate()).format(
          "dddd, MMMM Do YYYY, HH:mm:ss"
        )}</b> is the subscription renewal date. Please check your bank account to ensure smooth operations.`,
        closeOnClick: true,
        closeButton: true,
        on: {
          close: () => {
            localStorage.setItem("is_remind_subscription_renew", false);
          }
        }
      })
      .open();
  } else if (
    // do not repeat reminders
    userInfo.claims &&
    userInfo.claims.role === "owner" &&
    daysDiff > 10
  ) {
    localStorage.removeItem("is_remind_subscription_renew");
  }
  return null;
};

const checkRole = ({ role, routeTo }) => {
  let roleList = routeTo.route.displayRoles || [];
  let masterRoleList = (routeTo.route.masterRoute || {}).displayRoles || [];
  if (roleList.includes(role) || masterRoleList.includes(role)) {
    return true;
  }

  return false;
};
const getCheckAccessPath = routeTo => {
  if (routeTo.route.checkAccessPath) {
    return routeTo.route.checkAccessPath;
  }
  if (routeTo.route.masterRoute) {
    return `${routeTo.route.masterRoute.parent}/${routeTo.route.masterRoute.name}`;
  }
  if (routeTo.route.parent) {
    return `${routeTo.route.parent}/${routeTo.route.name}`;
  }
  return "";
};

const checkGroup = async ({ groupIDs, routeTo, tenantId }) => {
  //for some no need check group
  if (routeTo.route.disableInGroup) return true;
  let pathName = getCheckAccessPath(routeTo);
  for (let i = 0; i < (groupIDs || []).length; i++) {
    let group = await getGroup(groupIDs[i], tenantId);
    if (group && group.menuIDs && group.menuIDs.includes(pathName)) {
      return true;
    }
  }

  return false;
};

const saveIDToken = user => {
  return user.getIdToken().then(idToken => {
    if (process.env.NODE_ENV === "development" && loggerEnable === true) {
      const date = new Date();
      // eslint-disable-next-line no-console
      console.log(
        `[AUTH] Save new id token... ${date.toLocaleTimeString()}.${date.getMilliseconds()}`
      );
    }
    axios.defaults.headers.id_token = idToken;
    localStorage.setItem("login_tenant", user.tenantId);
    return localStorage.setItem("id_token", idToken);
  });
};

const removeToken = () => {
  localStorage.removeItem("id_token");
  localStorage.removeItem("login_tenant");
  localStorage.removeItem("homeUrl");
  //remove gapi login token
  localStorage.removeItem("gapi.access_token");
  localStorage.removeItem("gapi.email");
  localStorage.removeItem("gapi.expires_at");
  localStorage.removeItem("gapi.first_name");
  localStorage.removeItem("gapi.full_name");
  localStorage.removeItem("gapi.id");
  localStorage.removeItem("gapi.id_token");
  localStorage.removeItem("gapi.image_url");
  localStorage.removeItem("gapi.last_name");
};

const getToken = cb => {
  if (process.env.NODE_ENV === "development" && loggerEnable === true) {
    const date = new Date();
    // eslint-disable-next-line no-console
    console.log(
      `[AUTH] Getting id token... ${date.toLocaleTimeString()}.${date.getMilliseconds()}`
    );
  }

  return auth.onAuthStateChanged(user => {
    if (user) {
      saveIDToken(user).then(() => {
        cb();
      });
    }
  });
};

const getTenant = cb => {
  if (process.env.NODE_ENV === "development" && loggerEnable === true) {
    const date = new Date();
    // eslint-disable-next-line no-console
    console.log(
      `[AUTH] Getting tenant... ${date.toLocaleTimeString()}.${date.getMilliseconds()}`
    );
  }

  return auth.onAuthStateChanged(user => {
    if (user) {
      user.getIdTokenResult(true).then(async value => {
        let url = await getHomeUrl(value.claims);
        localStorage.setItem("homeUrl", url);
        let tenantLocal =
          localStorage.getItem("login_tenant") !== "null"
            ? localStorage.getItem("login_tenant")
            : "";
        cb(value.claims.tenantId || tenantLocal); // TODO: for testing tenant
      });
    } else {
      cb("");
    }
  });
};

const getHomeUrl = async claims => {
  if (claims.homeUrl) return claims.homeUrl;
  else if (claims.role === "owner")
    return "/dashboard/swimlane/commercial/sales";
  else if (claims.role === "user") {
    let groupIDs = claims.groupIDs;
    let canAccessDashboard = false;
    let canAccessMyTimeLog = false;
    for (let i = 0; i < groupIDs.length; i++) {
      let group = await getGroup(groupIDs[i], claims.firebase.tenant);
      if (
        group &&
        group.menuIDs &&
        group.menuIDs.includes("dashboard-main/commercial-board")
      ) {
        canAccessDashboard = true;
        break;
      }
      if (
        group &&
        group.menuIDs &&
        group.menuIDs.includes("hr-main/my-time-log")
      ) {
        canAccessMyTimeLog = true;
      }
    }
    if (canAccessDashboard) {
      return "/dashboard/swimlane/commercial/sales";
    } else if (canAccessMyTimeLog && claims.payType === "hourly") {
      return "/my-time-log?openTimer=1";
    } else if (canAccessMyTimeLog) {
      return "/my-time-log";
    }
  }
  return "/settings/profile";
};

const getSetting = tenant => {
  return firestore
    .collection("/system_settings")
    .doc(tenant)
    .get()
    .then(snap => {
      if (snap.exists) {
        let doc = snap.data();
        doc.id = snap.id;
        return doc;
      }
    });
};

const getGroup = (id, tenant) => {
  return firestore
    .collection(`/system_client/${tenant}/administration_group`)
    .doc(id)
    .get()
    .then(snap => {
      if (snap.exists) {
        let doc = snap.data();
        doc.id = snap.id;
        return doc;
      }
    });
};
const getLoginTenantId = email => {
  return axios.post(`/tenant`, { email }).then(result => {
    let tenantIds = [];
    result.data.forEach(item => {
      tenantIds.push(item.tenantId);
    });
    return tenantIds;
  });
};

const resetUserPassword = (loginTenant, email) => {
  return axios
    .post(`/admin/reset-password`, { loginTenant, email })
    .then(result => {
      return result.data;
    });
};

const verifyUserResetPasswordToken = code => {
  return axios.post(`/admin/reset-password-verify`, { code }).then(result => {
    return result.data;
  });
};

const updateUserPasswordReset = (code, password) => {
  return axios
    .post(`/admin/reset-password-update`, { code, password })
    .then(result => {
      return result.data;
    });
};

const sendEmailVerification = (loginTenant, uid) => {
  return axios
    .post(`/admin/email-verification`, { loginTenant, uid })
    .then(result => {
      return result.data;
    });
};

const updateEmailVerification = code => {
  return axios
    .post(`/admin/update-email-verification`, { code })
    .then(result => {
      return result.data;
    });
};

const getFailInvoices = () => {
  return axios.get("/subscription/get-fail-invoices").then(result => {
    return (result.data || {}).data || [];
  });
};

export {
  signIn,
  signOut,
  checkAuth,
  saveIDToken,
  removeToken,
  getToken,
  getTenant,
  getLoginTenantId,
  resetUserPassword,
  verifyUserResetPasswordToken,
  updateUserPasswordReset,
  sendEmailVerification,
  updateEmailVerification
};
