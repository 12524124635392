export default {
  workOrderList: [],
  workOrder: {}

  // workOrderActionList: [],
  // workOrderAction: {},

  // workOrderTaskList: [],
  // workOrderTask: {},

  // response: []
};
