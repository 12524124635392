import { FirebaseActions } from "../../../../services/firebase.service";
import * as types from "./types";
import * as constant from "../../../../utility/const";

export default tenant => {
  // const workOrder = new FirebaseActions(
  //   `/system_client/${tenant}/work_order`,
  //   "workOrder"
  // );

  const workOrderAction = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    "workOrderAction"
  );

  const workOrderTask = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "workOrderTask"
  );

  const purchaseOrderAction = new FirebaseActions(
    `/system_client/${tenant}/work_order_task`,
    "purchaseOrder"
  );

  return {
    // bindWorkOrder: workOrder.bindDocument,
    // bindWorkOrderList: workOrder.bindCollection,
    // bindWorkOrderListBys: workOrder.bindCollectionBys,
    // unbindWorkOrderList: workOrder.unbindCollection,
    // createWorkOrder: workOrder.createDocument,

    bindWorkOrderAction: workOrderAction.bindDocument,
    bindWorkOrderActionList: workOrderAction.bindCollection,
    getWorkOrderAction: workOrderAction.getDocument,
    getWorkOrderActionBys: workOrderAction.getDocumentBys,

    bindWorkOrderTaskListBy: workOrderTask.bindCollectionBy,
    bindWorkOrderTaskListBys: workOrderTask.bindCollectionBys,
    bindWorkOrderTask: workOrderTask.bindDocument,
    createWorkOrderTask: workOrderTask.createDocument,
    updateWorkOrderTask: workOrderTask.updateDocument,
    deleteWorkOrderTask: workOrderTask.deleteDocument,

    getWorkOrderTaskListBys: workOrderTask.getDocumentBys,

    bindWorkOrderResponse({ commit }, actionId) {
      FirebaseActions.getCollection(
        `/system_client/${tenant}/operation_dashboard/${actionId}/response`
      ).then(response => {
        commit(types.SET_WO_TASK_RESPONSE, response);
      });
    },

    editTaskPosition({ commit }, { taskId, actions, priority }) {
      commit(types.SET_TASK_PROPERTY_BY_ID, {
        taskId,
        prop: "actions",
        value: actions
      });
      commit(types.SET_TASK_PROPERTY_BY_ID, {
        taskId,
        prop: "priority",
        value: priority
      });
    },
    setFilterSearch({ commit }, payload) {
      commit(types.SET_FILTER_SEARCH, payload);
    },

    setFilterWeek({ commit }, payload) {
      commit(types.SET_FILTER_WEEK, payload);
    },

    bindPurchaseOrderListBys: purchaseOrderAction.bindCollectionBys,
    bindPurchaseOrder: purchaseOrderAction.bindDocument,
    unbindPurchaseOrder: purchaseOrderAction.unbindDocument,

    getPurchaseOrder: purchaseOrderAction.getDocumentBys,
    createPurchaseOrder: purchaseOrderAction.createDocument,
    updatePurchaseOrder: purchaseOrderAction.updateDocument,

    // bindPurchaseOrderListByProjectId({ dispatch }, projectId) {
    //   return dispatch("bindPurchaseOrderListBys", [
    //     {
    //       prop: "projectId",
    //       val: projectId,
    //       op: "=="
    //     },
    //     {
    //       prop: "taskType",
    //       val: "purchase-order",
    //       op: "=="
    //     },
    //     {
    //       prop: constant.PROP_IS_DELETED,
    //       op: "!=",
    //       val: true
    //     }
    //   ]);
    // },

    bindPurchaseOrderResponse({ commit }, actionId) {
      return FirebaseActions.getCollection(
        `/system_client/${tenant}/operation_dashboard/${actionId}/response`
      ).then(response => {
        commit(types.SET_PO_RESPONSE, response);
        return response;
      });
    },

    async getPurchaseOrderListByProjectId({ dispatch, commit }, projectId) {
      return await dispatch("getPurchaseOrder", [
        {
          prop: "projectId",
          val: projectId,
          op: "=="
        },
        {
          prop: "taskType",
          val: "purchase-order",
          op: "=="
        },
        {
          prop: constant.PROP_IS_DELETED,
          op: "!=",
          val: true
        }
      ]).then(response => {
        commit(types.SET_PO_LIST, response);
        return response;
      });
    }
  };
};
